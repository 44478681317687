// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
    _12: ImageType,
    _13: ImageType,
    _14: ImageType,
    _15: ImageType,
    _16: ImageType,
    _17: ImageType,
  },
};

class Blog_2017_06_08_Fat_Mans_Misery extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3BIII" title="Fat Man's Misery" />
        <p>
          This is the first time I have ever dropped into the wrong{' '}
          <GlossaryLink>drainage</GlossaryLink>.
        </p>
        <p>
          I intended for us to do the east fork since neither of us had done it.
          It wasn’t until we were at the first rappel that I said to Randy,
          "This looks exactly like the first rappel of the west fork."
        </p>
        <p>
          I create my own GPX routes and tend to get carried away with the
          details I add. I marked the west fork drainage point so I would know
          NOT to turn down there. I remember going down the drainage thinking
          that it didn’t look like it connected properly to the other fork but
          just kept going. Didn’t matter, though. There are some really cool
          places in the west fork that I was sad to miss so I’m glad I got to
          experience them again.
        </p>
        <Image caption="Morning at the highway" image={data._1} />
        <p>
          We started around 8:00 and quickly worked our way up the saddle and
          around to the back side of Checkerboard Mesa.
        </p>
        <p>
          A few short breaks for water and we found ourselves bypassing the many{' '}
          <GlossaryLink>potholes</GlossaryLink> that make up the top layer of
          the canyon. Randy thinks that it would be fun to come done here one
          day and "practice" pothole escapes by going from the bottom up. I
          nodded in agreement and we continue onward. Perhaps someday we can
          spend a few days back behind Checkerboard, hit both forks, and maybe
          spend an afternoon playing in the potholes to cool off, practice
          escaping, and scaring the thousands of tadpoles whose homes we are
          invading.
        </p>
        <p>
          We arrive at the first rappel, I realize my mistake, and we decide to
          keep going instead of backtracking to the other fork. We are doing
          Heaps in two days and this is our fitness canyon and we don't want it
          to be too hard.
        </p>
        <hr />
        <p>
          Fat Man’s Misery West has 4{' '}
          <GlossaryLink id="arch">arches</GlossaryLink> (
          <GlossaryLink>natural bridges</GlossaryLink>, actually). These two are
          found near the beginning before the confluence with East.
        </p>
        <Image image={data._3} />
        <Image
          caption="Randy got a big smile when he came upon this first arch"
          image={data._4}
        />
        <Image
          caption="This arch is easily missed – you can bypass the downclimb to get to it and never realize it is there."
          image={data._5}
        />
        <hr />
        <p>
          We reach the confluence after an hour or so. It’s pretty obvious where
          it is because the canyon really opens up for a bit before slotting up
          again. We <GlossaryLink id="hand-line">hand-lined</GlossaryLink> the
          drop down and continued onward.
        </p>
        <Image
          caption="After the confluence with East, the slot only gets better and better"
          image={data._6}
        />
        <p>
          Eventually we reach my favorite part of the canyon and one of the best
          parts of any canyon I have ever experienced. The canyon is a flowing
          stream now and we reach a very dark, serene chamber. The final two
          arches are located within this little area and requires a short swim
          in warm water to continue.
        </p>
        <p>
          I had to do a lot of tweaking on the images below to get them to be
          anything but black. This little section, combined with everything
          before it and the Parunuweap narrows after, make this canyon worth
          every step of the impending exit. Absolutely spectacular.
        </p>
        <Image caption="Randy under 3rd arch" image={data._7} />
        <Image
          caption="Final arch – we swapped places for this picture"
          image={data._8}
        />
        <p>
          After this dark chamber we encounter Warm Spring – a little waterfall
          coming out of the rock that drastically warms up the water in the
          canyon. Then comes a series of water slides. The canyon finally ends
          in a grotto where it drains into Parunuweap, the East Fork of the
          Virgin River.
        </p>
        <Image caption="End of Fat Man's" image={data._9} />
        <Image
          caption="Confluence Fat Man’s Misery and Parunuweap"
          image={data._10}
        />
        <p>
          We are now done with the{' '}
          <GlossaryLink>technical section</GlossaryLink> and it’s about
          lunchtime so we gear down and take a break to enjoy this hidden
          treasure so few people will ever see. It is such a peaceful place.
        </p>
        <hr />
        <p>
          We have a quarter of a mile or so of river walking before we reach our
          exit point. This section is exactly like the famous Zion Narrows
          except for two key differences:
        </p>
        <ol>
          <li>It is completely void of other people.</li>
          <li>
            The bottom of the river is either sand or bedrock, so it’s much
            easier to walk without stumbling.
          </li>
        </ol>
        <Image caption="Parunuweap" image={data._11} />
        <Image caption="Parunuweap" image={data._12} />
        <Image
          caption="At the exit there is this memorial plaque"
          image={data._13}
        />
        <p>
          A very long and difficult hike back to the car takes up the rest of
          the day and all our energy reserves. On the way back, though, we were
          privileged to be near some of the more amazing animals I have seen in
          the park.
        </p>
        <Image caption="Half tadpoles, half frogs" image={data._14} />
        <Image
          caption="Many fully grown frogs were hanging out on the canyon walls near potholes"
          image={data._15}
        />
        <Image
          caption="There are three desert bighorn sheep in this picture"
          image={data._16}
        />
        <Image
          caption="This big guy was hanging out on a rock right in the middle of the trail. He stuck around for a while and allowed us to walk around him and take pictures from many angles. With his tail he was over a foot long."
          image={data._17}
        />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2017-06-08-fat-mans-misery')(
  Blog_2017_06_08_Fat_Mans_Misery
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/10.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/11.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _12: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _13: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/13.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _14: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/14.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _15: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/15.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _16: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/16.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _17: file(
      relativePath: { eq: "images/blog/2017-06-08-fat-mans-misery/17.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
